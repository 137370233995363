import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux'
import styled from 'styled-components';

/* Components */
import { colors } from "../../common/components/colors";
import { LogoLoading } from "../../common/components/loading";

/* Container */
import Photo from './containers/photo';
import Intro2 from "./containers/intro2";
import ContactNoForm from "./containers/contactnoform";
import Expertise2 from "./containers/expertise2";
import Firm from "./containers/firm";
import Disclosure from './containers/disclosure';

/* Middleware */
import { tryGetCertifiedAdvisor } from "./middleware/profile";
import { ButtonToTop } from "./containers/components";
import {tryPostMailchimp, tryPostMixpanel} from "../../common/utils/tracking";
import moment from "moment";

const LoadingCenter = styled.div`
    margin: 60px auto;
    text-align: center;
`;
const Row = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  @media screen and (max-width: 980px) {
    flex-wrap: wrap;
  }
  height: 100vh;
  
  & .right-transition-enter {
    opacity: 0;
  }
  & .right-transition-enter-active {
    opacity: 1;
    transition: opacity 200ms;
  }
`;
const Left = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: calc(100% - 860px);
  @media screen and (max-width: 1680px) {
    width: calc(100% - 725px);
  }
  @media screen and (max-width: 980px) {
    position: relative;
    width: 100%;
    height: 580px;
  }
  z-index: 1;
  background-color: ${colors.background30};
`;
const Right = styled.div`
  padding-left: calc(100% - 860px);
  width: 860px;
  overflow-y: auto;
  z-index: 2;
  justify-content: center;
  @media screen and (max-width: 1680px) {
    padding-left: calc(100% - 725px);
    width: 725px;
  }
  @media screen and (max-width: 980px) {
    padding-left: 0;
    width: 100%;
  }
`;

export default function IFrameAdvisorProfileV2() {
  const [isLoading, setIsLoading] = useState(true);
  const [advisor, setAdvisor] = useState({
    "id": 0,
    "firstName": "",
    "lastName": "",
    "photos": [],
    "intro": { "video": "", "description": "", "animal": "", "mbti": "", "hobby": "", "zodiac": "", "birthOrder": "" },
    "expertise": [],
    "specialities": [],
    "services": [],
    "disclosure": null,
    "logos": [],
    "firmSec": null,
    "advisorSec": null,
    "firmName": null,
    "firmBio": null,
    "firmLogo": null,
    "firmAddress": {},
    "isLoading": true
  });
  const contactRef = useRef(null)
  const expertiseRef = useRef(null)
  const servicesRef = useRef(null)
  const firmRef = useRef(null)
  const disclosureRef = useRef(null)
  const location = useLocation();
  const dispatch = useDispatch();
  const pageTopRef = useRef(null);

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    const url = location.pathname.split('/');
    const advisorHandle = url[url.length - 1];
    const advisorResults = await dispatch(tryGetCertifiedAdvisor(advisorHandle))
    const searchParams = new URLSearchParams(window.location.search);
    let t1 = searchParams.get("t1"); // anonymousId
    let t2 = searchParams.get("t2"); // requestType
    if (t1 === null || t1 === "null" || t1 === undefined || t1 === "") {
      t1 = sessionStorage.getItem("t1");
    }
    if (t1 !== null) {
      await dispatch(tryPostMixpanel(null, t1, "clientAcquisitionViewAdvisorProfile", { advisorId: advisorResults.id, advisorName: (advisorResults.firstName+" "+advisorResults.firstName) }));
      await dispatch(tryPostMailchimp({"t1": t1, "tagId": "ACQPROFILE", "tagValue": "TRUE"}))
      await dispatch(tryPostMailchimp({"t1": t1, "tagId": "ACQUPDATED", "tagValue": moment().format("MM/DD/YYYY")}))
    }
    if (t2 === null || t2 === "null" || t2 === undefined || t2 === "") {
      t2 = sessionStorage.getItem("t2");
    }
    setAdvisor({
      ...advisorResults,
      "photos": advisorResults.photos?.filter(p => { return (p !== null) }),
      "logos": advisorResults.logos?.filter(l => { return (l !== null) }),
      "expertise": advisorResults.expertise !== null ? advisorResults.expertise : [],
      "isLoading": false,
    });
  }

  useEffect(() => {
    if (!advisor.isLoading) {
      //console.log(advisor)
      setIsLoading(false)
    }
  }, [advisor])

  if (isLoading) {
    return (<LoadingCenter><LogoLoading /></LoadingCenter>)
  }

  return (
    <Row>
      <Left>
        <Photo photos={advisor.photos} />
      </Left>
      <Right>
        <div ref={pageTopRef}>
          <Intro2
            name={advisor.firstName}
            intro={advisor.intro}
          />
        </div>
        {/*<div ref={contactRef}>*/}
        {/*  <ContactNoForm*/}
        {/*    advisorId={advisor.id}*/}
        {/*    advisorFirstName={advisor.firstName}*/}
        {/*  />*/}
        {/*</div>*/}
        <div ref={expertiseRef}>
          <Expertise2
            expertise={advisor.expertise}
          />
        </div>
        <div ref={firmRef}>
          <Firm
            firm_name={advisor.firmName}
            firm_bio={advisor.firmBio}
            firm_logo={advisor.logos.length > 0 ? advisor.logos[0] : advisor.firmLogo}
            firm_address={advisor.firmAddress.address}
            firm_sec={advisor.firmSec}
          />
        </div>
        <div ref={disclosureRef}>
          <Disclosure
            disclosure={advisor.disclosure}
            advisor_sec={advisor.advisorSec}
          />
        </div>
        <ButtonToTop scrollToTop={() => pageTopRef.current.scrollIntoView({ behavior: 'smooth' })} />
      </Right>
    </Row>
  )
}